import styled from "styled-components";

export const PrincipalOffer = styled.a`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #0e1d41;

  width: 100%;
  height: 40px;

  border: none;

  color: white;
  /* text-shadow: 3px 2px 0px #0092ff; */

  font-family: "Gotham";
  text-decoration: none;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.5px;
`;

export const NormalOffer = styled.a`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 40px;

  border: none;

  color: white;
  /* text-shadow: 3px 2px 0px #0092ff; */

  font-family: "Gotham";
  text-decoration: none;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -0.5px;
`;

export const NonOfferItem = styled.a`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  text-decoration: none;
`;
