import Modal from './modal/modal';
import { Responsibility } from './Responsibility/Responsibility';

function App() {
  return (
    <div>
      <Modal hiddeHeadline={false} showModal={false} />
      <Responsibility />
    </div>
  );
}

export default App;
