import styled, { keyframes } from "styled-components";

export const scaleAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

// Modal
export const ModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

export const ModalContent = styled.div`
  position: absolute;
  z-index: 20;
  background-color: #232426;
  border: 4px solid #0092ff;
  border-radius: 32px;
  min-width: 90vw;

  @media (min-width: 768px) {
    min-width: 450px;
  }
`;

export const ModalGamePoints = styled.div`
  position: absolute;
  z-index: 20;
  width: 100%;

  @media (min-width: 550px) {
    width: 500px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// Modal Rodadas
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 48px;
`;

export const OffersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OffersTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;

  width: 95%;
  background-color: #0092ff;
  border: 3px solid #0092ff;
  border-radius: 15px;

  gap: 3px;

  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    background-color: #0e1d41;

    width: 100%;
    height: 60px;

    font-family: "Gotham";
    color: white;

    h3 {
      font-size: 15px;

      span {
        font-size: 16px;
        /* text-shadow: 3px 2px 0px #0092ff; */
      }
    }
  }

  .title1 {
    border-top-left-radius: 15px;
  }

  .title2 {
    border-top-right-radius: 15px;
  }

  .offerslist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .offerslist .last-item1 {
    border-bottom-left-radius: 15px;
  }

  .offerslist .last-item2 {
    border-bottom-right-radius: 15px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0092ff;
  border: none;
  border-bottom: 6px solid #064879;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;
  animation: ${scaleAnimation} 1s infinite;
`;

export const Logo = styled.img`
  width: 109px;
  height: 20px;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 100%;
  height: 42px;

  background-color: #ff4747;

  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @media (max-width: 768px) {
    height: 35px;
  }
`;

export const HeaderText = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  text-transform: uppercase;
  color: white;
  font-weight: 800;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 48px;
`;
