import styled from "styled-components";

export const ResponsibilityContainer = styled.div`
  position: absolute;
  bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 9999;

  img {
    width: 65%;
    height: auto;

    @media (min-width: 768px) {
      width: 250px;
    }
  }
`;
