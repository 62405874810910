import React, { useState, useEffect } from "react";
import "./style.css";
import Card from "../scratch/Card";
import styled from "styled-components";
import ModalWin from "../modal-win/Modal";

interface ModalProps {
    token?: string | null;
    showModal: boolean;
    hiddeHeadline: boolean;

}

const CardComponent = styled.div`
    display: block;
    border: transparent;
  
`

const ModalComponent = styled.div`
    background-color: #232426;
    padding: 20px 10px;
    border-radius: 8px;
`

const Modal: React.FC<ModalProps> = ({ token, showModal, hiddeHeadline }) => {
    const [modal, setModal] = useState<boolean>(false);
    const [scratch, setScratch] = useState<boolean>(true);

    useEffect(() => {
        if (showModal) {
            setModal(true);
            setScratch(false);
        }
    }, [showModal]);

    return (
        <div id="overlay">
            <div
                style={{
                    backgroundColor: "transparent",
                    zIndex: 999,
                }}
            >
                <iframe
                    src="https://cassinopix.com/"
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        opacity: 1,
                        filter: "blur(1.5px)",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1000,
                    }}
                ></iframe>
            </div>
            <div
                id="modal"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1001,
                }}
            >
                {modal && (
                    <ModalComponent>
                        <ModalWin token={token} />
                    </ModalComponent>
                )}
                {scratch && (
                    <CardComponent>
                        <Card />
                    </CardComponent>
                )}
            </div>
        </div>
    );
};

export default Modal;
